.dashboard-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  width: 89%;
  max-width: 833px;
  margin: auto;

  .dashboard-infos-wrap{
    .dashboard-card{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      position: relative;
      z-index: 2;
      
      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        background: transparent;
        backdrop-filter: blur(10px);
        border: 1px solid #47999D;
      }
    
      /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
      @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: transparent;
        backdrop-filter: blur(10px);
        border: 1px solid #47999D;
      }

      border-radius: 10px;
      padding: 13px 0;

      .card-title {
        font-family: Montserrat Bold;
        font-weight: 500;
        font-size: 20px;
        color: #47999D;
      }
  
      .card-value {
        font-family: Montserrat Bold;
        font-weight: bold;
        font-size: 24px;
        font-weight: bold;
        color: #D3EBEA;
      }
    }
  }
}