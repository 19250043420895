.landing-footer{
    margin-top: auto;
    padding: 32px 15%;
    z-index: 2;

    .landing-footer-item-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .landing-footer-item-title{
            font-family: Montserrat Bold;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 22px;
            color: #F49E06;
            margin-bottom: 4px;
        }

        .landing-footer-item-value{
            font-family: Montserrat Bold;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            color: #E7E2C2;
        }
    }
}

@media screen and (max-width: 812px) {
    .landing-footer{
        padding: 32px 10%;
    }
}