.choose-token-poper {
    min-height: 605px;
    width: 100%;
    padding: 0px !important;

    .choose-token-poper-header{
        padding-top: 20px;
        margin: 0px 20px;

        .choose-token-poper-header-title{
            text-align: center;
            font-family: Montserrat SemiBold;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            color: #FFFFFF;
        }

        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .choose-token-poper-header-input {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;

            background: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            outline: none;

            .MuiOutlinedInput-input {
                font-family: Montserrat Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);
                padding: 14px 20px;
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: rgba(255, 255, 255, 0.2) !important;
            }
        }
    }

    .choose-token-poper-body{
        height: 420px;
        overflow: auto;
        margin-bottom: 20px;

        .choose-token-poper-body-item{
            padding: 0px 20px;
            
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover{
                background: rgba(255, 255, 255, 0.2);;
            }

            .choose-token-poper-body-item-img{
                width: 24px;
                height: 24px;
                border-radius: 24px;
                box-shadow: rgb(0 0 0 / 8%) 0px 6px 10px;
            }

            .choose-token-poper-body-item-desc{
                display: flex;
                align-items: center;
                margin-left: 10px;
                width: 100%;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                padding: 14px 0;

                .choose-token-poper-body-item-name{
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;                
                    color: #FFFFFF;
                }
                
                .choose-token-poper-body-item-balance{
                    margin-left: auto;
    
                    p{
                        font-family: Montserrat Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        color: #FFFFFF;
                    }
                }
            }            
        }
    }
}
