.landing-background{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.5;

    .landing-background-blobs-top{
        overflow-y: scroll;
    }
}

@media screen and (max-width: 1200px) {
    .landing-background{
        display: none;
    }
}