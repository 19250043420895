@import "../assets/styles/fonts.scss";

* {
  outline: none;
  box-sizing: border-box;
}

html,
body,
#root {
  font-family: "Square";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  margin: 0;
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

p{
  margin: 0;
}

.web3modal-modal-lightbox{
  z-index: 10 !important;
  background: rgba(0, 0, 0, 0.2) !important;
  backdrop-filter: blur(100px) !important;

  .web3modal-modal-card{
    background: #181B1C;
    backdrop-filter: blur(100px);
    border-radius: 10px;

    .web3modal-provider-wrapper{
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 0;
      padding: 0;

      .web3modal-provider-container{
        background-color: rgb(0,0,0,0);
        border-radius: 0;

        &:hover{
          background-color: rgb(0,0,0,0.2);
        }

        .web3modal-provider-name{
          font-family: Montserrat SemiBold;
          font-weight: 600;
          font-size: 24px;
          text-align: center;
          color: #FFFFFF;
        }

        .web3modal-provider-description{
          font-family: Montserrat Light;
          font-weight: 300;
          font-size: 14px;
          text-align: center;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}

.walletconnect-qrcode__base{
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background: rgba(0, 0, 0, 0.2) !important;
    backdrop-filter: blur(100px) !important;
  }

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(0, 0, 0, 0.8);
  }

  .walletconnect-modal__base{
    border-radius: 10px;
    padding: 30px 20px 20px 20px;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background: #181B1C;
      backdrop-filter: blur(100px);
    }
  
    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: #181B1C;
    }

    .walletconnect-modal__header{
      .walletconnect-modal__headerLogo{
        display: none;
      }
  
      p{
        margin-left: 24px;

        font-family: Montserrat SemiBold;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #FFFFFF;

      }
  
      .walletconnect-modal__close__wrapper{
        background: none;
        right: 23px;
  
        .walletconnect-modal__close__icon{
          .walletconnect-modal__close__line1{
            border: 1px solid rgb(255, 255, 255);
          }
          .walletconnect-modal__close__line2{
            border: 1px solid rgb(255, 255, 255);
          }
        }
      }
    }

    .walletconnect-modal__mobile__toggle{
      margin-bottom: 20px;
      background: none;
      width: 60%;

      .walletconnect-modal__mobile__toggle_selector{
        height: 1px;
        background: #FFFFFF;
        top: 34px;
      }

      a{
        font-family: Montserrat SemiBold;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #FFFFFF;
        cursor: pointer;

        &:last-child{
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    .walletconnect-modal__mobile__toggle.right__selected{
      a{
        &:nth-child(2){
          color: rgba(255, 255, 255, 0.6);
        }

        &:last-child{
          color: #FFFFFF;
        }
      }
    }

    .walletconnect-qrcode__text{
      font-family: Montserrat Light;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
    }

    .walletconnect-qrcode__image{
      background: #FFFFFF;
      border-radius: 10px;
      padding: 13px;
    }

    .walletconnect-modal__footer{
      font-family: Montserrat Light;
      font-weight: 300;
      font-size: 14px;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
    }

    .walletconnect-modal__base__row{
      h3{
        font-family: Montserrat SemiBold;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #FFFFFF;
      }
    }
  }
}